@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.font-Gluten{
  font-family: "Gluten", cursive !important;
}

.custom-ok-button {
  background-color: #C13B44; 
  color: white;
  transition: background-color 0.3s, color 0.3s;
}

.custom-ok-button:hover {
  background-color: #c0555c !important; 
  color: white;
}
.custom-cancel-button {
  display: none;
  border:1px solid grey;
}

.custom-cancel-button:hover {
  border: 1px solid #C13B44 !important;
  color: #C13B44 !important;
}
::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #b6b1b1;
    border: 2px solid transparent;
    border-radius: 4px;
    background-clip: content-box;
  }
  .contactImage{
    height:500px;
    width: 100%;
  }
  @media (max-width: 639px) {
    .contactImage {
      height: 250px; /* Adjust height for extra small screens */
    }
  }

  .abril-fatface-regular {
    font-family: "Abril Fatface", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  /* For small screens (sm) */
  @media (min-width: 640px) and (max-width: 767px) {
    .contactImage {
      height: 400px; /* Adjust height for small screens */
    }
  }
  
  /* For medium screens (md) */
  @media (min-width: 768px) and (max-width: 1023px) {
    .contactImage {
      height: 500px; /* Adjust height for medium screens */
    }
  }
  
  /* For large screens (lg) */
  @media (min-width: 1024px) {
    .contactImage {
      height: 500px; /* Adjust height for large screens */
    }
  }

  @layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
   /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
  }
} 
  