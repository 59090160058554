
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@400;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* App.css */
@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.shine-button {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.2) 75%);
  background-size: 200% 100%;
  animation: shine 1.5s infinite;
  transition: background-size 0.3s ease-in-out;
}

.shine-button:hover {
  background-size: 300% 100%;
  animation: shine 1s infinite linear;
}

@keyframes flicker {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.flicker {
  animation: flicker 1s infinite; 
}


@keyframes moveText {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); /* Move text to the right */
  }
  100% {
    transform: translateY(0); /* Return to original position */
  }
}

.moving-text {
  animation: moveText 2s infinite; /* Adjust duration as needed */
}
